<template class="">
  <div class="overflow-auto">
    <div class="row">
      <div class="col-12 col-lg-12">
        <ul class="list-group">
          <li class="list-group-item" 
            v-for="(item, index) in salesChannel" :key="index"  
            :class="[
              { 'table-light': item.enabled === true }, 
              item.id ==='2' ? 'hide-display' : ''
            ]">
            <div class="row ml-2 p-2"  >
              <div class="col-lg-4 col-6 row justify-content-between">
                <div class="col-4">
                  <img :src="item.img" class="img-fluid rounded-circle" width="50px" />
                </div>
                <div class="col">
                  <h6 class="mb-0">{{ $t(item.name) }}</h6>
                  <p class="mb-0">
                    <CBadge color="info" v-if="item.gpPercent === 'NO GP'">
                      {{ item.gpPercent }}
                    </CBadge>
                    <CBadge color="success" v-else>
                      GP : {{ item.gpPercent }}
                    </CBadge>
                  </p>
                </div>
              </div>
              <div class="col-lg-8 col-6 text-right  pr-3" v-if="item.id == '101'">
                <CSwitch color="success" :checked.sync="item.enabled"
                @update:checked="updateChannelConfig(item, index)" />
              </div>
              <div class="col-lg-12 col-12 text-left " v-if="item.id == '101'">
                <div v-if="item.enabled === true">
                  <CAlert class="mr-4 mt-3 mb-3" color="secondary">
                  <CCol md="12" class="form-group row mt-1 mb-1">
                    <label id="productInformationCategoryLabel"
                      class="col-sm-3 col-form-label text-left text-dark w-100 w-sm-auto">
                       {{ $t('salesHours') }}
                    </label>
                    <div id="productInformationCategoryInputContainer" class="col-sm-9">
                      <Multiselect id="productPriceSellingTimeSelect" 
                        v-model="sellingTime"  :disabled="!item.isEdit"
                        :options="sellingTimelist" class="cursor "
                        :placeholder="$t('All Days')" label="name" track-by="id"></Multiselect>
                        <CAlert
                          id="addSalesHoursSettingInfoAlert"
                          color="info" class="d-inline-block col-12 mt-2"  style="font-size: 14px; padding: 8px 12px"
                        >
                          <i class="fi fi-rr-info mr-1"></i>
                          {{ $t("setSellingHoursForProduct") }}
                        </CAlert>
                    </div>
                  </CCol>
                </CAlert>
                  <div class="row m-0">
                    <div class="col-lg-12 col-12 row">
                      <div class="col-lg-1 col-5">
                        <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.deliveryOnDemand"
                          @update:checked="updateProductPriceByChannel(item, index)"   :disabled="!item.isEdit" />
                      </div>
                      <div class="col-lg-2 col-7 ">
                        <p class="mt-2 ml-3 text-left">{{ $t('deliveryOnDemand') }}</p>
                      </div>

                      <div class="col-lg-5 col-12 text-left row mr-3">
                        <label class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                            {{ $t('difference') }}
                        </label>
                        <div class="col-sm-8 col-lg-8 col-8 ">
                          <input class="form-control text-danger"
                            :value="getDifferent(item.advancedPricing.deliveryOnDemand)"
                            v-if="Number(getDifferent(item.advancedPricing.deliveryOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryOnDemand)))"
                            disabled />
                          <input class="form-control  " :value="getDifferent(item.advancedPricing.deliveryOnDemand)"
                            disabled
                            v-else-if="Number(getDifferent(item.advancedPricing.deliveryOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryOnDemand))) === 0" />
                          <input class="form-control text-success"
                            :value="getDifferent(item.advancedPricing.deliveryOnDemand)" disabled v-else />
                        </div>
                      </div>

                      <div class="col-lg-4 col-12 text-left row ">
                        <label class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('pricePackage') }}
                        </label>
                        <div class="col-sm-9 col-lg-9 col-8" >
                          <CInput v-model.number="item.advancedPricing.deliveryOnDemand" placeholder="Price"
                            type="number" :disabled="item.isEdit === false" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <div class="col-lg-1 col-5">
                        <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.deliveryScheduled"  :disabled="!item.isEdit"
                          @update:checked=" updateProductPriceByChannel(item, index) " />
                      </div>
                      <div class="col-lg-2 col-7">
                        <p class="mt-2 ml-3 text-left">{{ $t('deliveryScheduled') }}</p>
                      </div>

                      <div class="col-lg-5 col-12 text-left row mr-3">
                        <label class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('difference') }}
                        </label>
                        <div class="col-sm-8 col-lg-8 col-8">
                          <input class="form-control text-danger"
                            :value="getDifferent(item.advancedPricing.deliveryScheduled)"
                            v-if="Number(getDifferent(item.advancedPricing.deliveryScheduled) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryScheduled)))"
                            disabled />
                          <input class="form-control  " :value="getDifferent(item.advancedPricing.deliveryScheduled)"
                            disabled
                            v-else-if="Number(getDifferent(item.advancedPricing.deliveryScheduled) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.deliveryScheduledGrabApp))) === 0" />
                          <input class="form-control text-success"
                            :value="getDifferent(item.advancedPricing.deliveryScheduled)" disabled v-else />

                        </div>
                      </div>

                      <div class="col-lg-4 col text-left row">
                        <label class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('pricePackage') }}
                        </label>
                        <div class="col-sm-9 col-lg-9 col-8" >
                          <CInput v-model.number="item.advancedPricing.deliveryScheduled" placeholder="Price"
                            type="number" :disabled="item.isEdit === false" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 row">
                      <div class="col-lg-1 col-5">
                        <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.selfPickUpOnDemand"  :disabled="!item.isEdit"
                          @update:checked=" updateProductPriceByChannel(item, index) " />
                      </div>
                      <div class="col-lg-2 col-7">
                        <p class="mt-2 ml-3 text-left">{{ $t('selfPickUpOnDemand') }}</p>
                      </div>

                      <div class="col-lg-5 col-12 text-left row mr-3">
                        <label class="col-sm-4 col-lg-4  col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('difference') }}
                        </label>
                        <div class="col-sm-8 col-lg-8 col-8">
                          <input class="form-control text-danger"
                            :value="getDifferent(item.advancedPricing.selfPickUpOnDemand)"
                            v-if="Number(getDifferent(item.advancedPricing.selfPickUpOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.selfPickUpOnDemand)))"
                            disabled />
                          <input class="form-control  " :value="getDifferent(item.advancedPricing.selfPickUpOnDemand)"
                            disabled
                            v-else-if="Number(getDifferent(item.advancedPricing.selfPickUpOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.SelfPickUp_OnDemand_GrabApp))) === 0" />
                          <input class="form-control text-success"
                            :value="getDifferent(item.advancedPricing.selfPickUpOnDemand)" disabled v-else />

                        </div>
                      </div>

                      <div class="col-lg-4 col col-12 text-left row ">
                        <label class="col-sm-3 col-lg-3 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('pricePackage') }}
                        </label>
                        <div class="col-sm-9 col-lg-9 col-8" >
                          <CInput v-model.number="item.advancedPricing.selfPickUpOnDemand" placeholder="Price"
                            type="number" :disabled="item.isEdit === false" />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 row">
                      <div class="col-lg-1 col-5">
                        <CSwitch color="info" class="mt-2" :checked.sync="item.purchasability.dineInOnDemand"  :disabled="!item.isEdit"
                          @update:checked="updateProductPriceByChannel(item, index)" />
                      </div>
                      <div class="col-lg-2 col-7">
                        <p class="mt-2 ml-3 text-left">{{ $t('dineInOnDemand') }}</p>
                      </div>

                      <div class="col-lg-5 col col-12 text-left row mr-3">
                        <label class="col-sm-4 col-lg-4 col-4 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('difference') }}
                        </label>
                        <div class="col-sm-8 col-lg-8 col-8">
                          <input class="form-control text-danger"
                            :value="getDifferent(item.advancedPricing.dineInOnDemand)"
                            v-if="Number(getDifferent(item.advancedPricing.dineInOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.dineInOnDemand)))"
                            disabled />
                          <input class="form-control  " :value="getDifferent(item.advancedPricing.dineInOnDemand)"
                            disabled
                            v-else-if="Number(getDifferent(item.advancedPricing.dineInOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(item.advancedPricing.dineInOnDemand))) === 0" />
                          <input class="form-control text-success"
                            :value="getDifferent(item.advancedPricing.dineInOnDemand)" disabled v-else />

                        </div>
                      </div>

                      <div class="col-lg-4 col text-left col-12 row ">
                        <label class="col-sm-3 col-lg-3 col-4  col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('pricePackage') }}
                        </label>
                        <div class="col-sm-9 col-lg-9 col-8" >
                          <CInput v-model.number="item.advancedPricing.dineInOnDemand" placeholder="Price"
                            type="number" :disabled ="item.isEdit === false"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mr-4" v-if="item.isEdit === false">
                    <button class="btn btn-primary btn-sm btn-block mr" @click="changeItemEdit(index)"
                      v-c-tooltip="{ content: $t('edit'), placement: 'bottom' }">
                      <i class="fi fi-rr-edit"></i>

                    </button>
                  </div>
                  <div class="col row pr-4" v-else>
                    <div style="width: 50%">
                      <button class="btn btn-success btn-sm btn-block ml-1" @click="updateChannelConfig(item, index)"
                        v-c-tooltip="{ content: $t('save'), placement: 'bottom' }">
                        <CIcon name="cil-save" />
                      </button>
                    </div>
                    <div style="width: 50%">
                      <button class="btn btn-dark btn-sm btn-block ml-2 m" @click="changeItemEdit(index)"
                        v-c-tooltip="{ content: $t('cancel'), placement: 'bottom' }">
                        <CIcon name="cil-x" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-9 col-lg-8 text-right   pr-3" v-else-if="item.id == '1'">
                <h5 class="text-success">{{ $t('price') }} : {{ item.price }}</h5>
              </div>
              <div class="col-lg-8 col-6 text-right  pr-3 " v-else>
                <CSwitch color="success" :checked.sync="item.enabled"
                  @update:checked="updateChannelConfig(item, index)" />  
              </div>
            </div>
            <div class="12 pl-2 " v-if="item.id !== '1' && item.id !== '101'&& item.id !== '2'"  >
              <div class="row pl-2 pr-4" v-if="item.enabled === true">
                  <div class="col-9 col-lg-10">
                    <div class="form-group row mb-0">
                      <div class="col-6 row">
                        <label class="col-md-5 col-sm-5 col-lg-4 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('difference') }}
                        </label>
                        <div class="col-md-7 col-sm-7 col-lg-8 ">

                          <input class="form-control text-danger" :value="getDifferent(item.price)"
                            v-if="Number(getDifferent(item.price) < 0) || Number.isNaN(parseInt(getDifferent(item.price)))"
                            disabled />
                          <input class="form-control  " :value="getDifferent(item.price)" disabled
                            v-else-if="Number(getDifferent(item.price) == 0) || Number.isNaN(parseInt(getDifferent(item.price))) === 0" />
                          <input class="form-control text-success" :value="getDifferent(item.price)" disabled v-else />

                        </div>
                      </div>
                      <div class="col-6 row">
                        <label class="col-sm-3 col-lg-3 col-form-label text-right text-dark w-100 w-sm-auto">
                          {{ $t('pricePackage') }}
                        </label>
                        <div class="col-sm-9 col-lg-9" v-if="item.isEdit === false">
                          <CInput v-model.number="item.price" placeholder="Price" type="number" disabled />
                        </div>
                        <div v-else class="col-sm-9 col-lg-9">
                          <CInput v-model.number="item.price" placeholder="GP (%)" type="number" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 col-lg-2" v-if="item.isEdit === false">
                    <button class="btn btn-primary btn-sm btn-block" @click="changeItemEdit(index)"
                      v-c-tooltip="{ content: $t('edit'), placement: 'bottom' }">
                      <i class="fi fi-rr-edit"></i>
                    </button>
                  </div>
                  <div class="col-2 row" v-else>
                    <div style="width: 50%">
                      <button class="btn btn-success btn-sm btn-block ml-1" @click="updateChannelConfig(item, index)"
                        v-c-tooltip="{ content: $t('save'), placement: 'bottom' }">
                        <CIcon name="cil-save" />

                      </button>
                    </div>
                    <div style="width: 50%">
                      <button class="btn btn-dark btn-sm btn-block ml-2"
                        v-c-tooltip="{ content: $t('cancel'), placement: 'bottom' }" @click="changeItemEdit(index)">
                        <CIcon name="cil-x" />
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
import { mapGetters } from 'vuex'
import pos from "@/services/local";
import Multiselect from "vue-multiselect";

export default {
  name: "Product-channels",
  props: [],
  components: {
    Multiselect
  },
  data() {
    return {
      details: [],
      checked: true,
      salesChannel: [],
      producrChannelList: [],
      sellingTimelist : [],
      sellingTime : null
    };
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),  
    productSKUdetail: {
      get() {
        return this.$store.getters.productSKUdetail;
      },
      set() {
        return this.$store.getters.productSKUdetail;
      },
    },
    priceFields() {
      return [
        {
          key: "isCheck",
          // label: this.$i18n.t('isMenu'),
          label: "Enabled",
          _classes: " text-dark font-weight-normal",
          sorter: false,
          _style: "width:10%",
          _props: { active: true },
          _cellProps: {
            id: { scope: "row" },
            class: { active: true, colSpan: 2 },
          },
        },
        {
          key: "channel",
          label: "Channel",
          sorter: false,
          _style: "width:50%",
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "difference",
          label: "Difference",
          sorter: false,
          _style: "width:15%",
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "Price",
          label: "Price",
          sorter: false,
          _style: "width:15%",
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "GP",
          label: "GP",
          sorter: false,
          _style: "width:15%",
          _classes: "text-dark font-weight-normal ",
        },
        {
          key: "action",
          label: "",
          sorter: false,
          _style: "width:10%",
          _classes: "text-dark font-weight-normal ",
        },
      ];
    },
    shopObjectId: {
        get() {
            return this.$store.getters.shopObjectId
        },
        set(newValue) {
            return this.$store.dispatch('setShop', newValue)
        },
    },
  },
  created() {
    this.getChannelByProductPLU()
    this.getManageSaleHours()
    // this.getSalesChannelsSetting();
  },
  mounted() { },
  methods: {
    getDifferent(item) {
      let difference = item - this.productSKUdetail.SKUPrice;
      return difference;
    },

    getChannelByProductPLU() {
      let params = {
          productPLUObjectId: this.productSKUdetail.ProductPLU.objectId
      };
      const headers = { 
            shopObjectId: this.shopObjectId ,
            // Authorization : token
      }      
      pos({
        url: "/api/v3.0/sellingchannel/product/channel",
        params: params,
        headers: headers,
        method: "get",
      }).then((res) => {
        let data = res.data.data
        this.producrChannelList = data
        this.getSalesChannelsSetting()

      })
    },
    getSalesChannelsSetting() {
      const headers = { shopObjectId: this.shopObjectId };
      const params = { shopObjectId: this.shopObjectId, type: "ALL" };

      pos({
        url: "/api/v3.0/sellingchannel/list",
        params: params,
        headers: headers,
        method: "get",
      })
        .then((res) => {
          const data = res.data.data || [];
          const producrChannelList = this.producrChannelList || [];
          const detail = [];

          // Helper function to initialize pricing and purchasability
          const initializeAdvancedPricing = () => ({
            deliveryOnDemand: this.productSKUdetail.SKUPrice ?? 0,
            deliveryScheduled: this.productSKUdetail.SKUPrice ?? 0,
            selfPickUpOnDemand: this.productSKUdetail.SKUPrice ?? 0,
            dineInOnDemand: this.productSKUdetail.SKUPrice ?? 0,
          });

          const initializePurchasability = () => ({
            deliveryOnDemand: false,
            deliveryScheduled: false,
            selfPickUpOnDemand: false,
            dineInOnDemand: false,
          });

          // Loop through sales channel data
          data.forEach((channel) => {
            let enabled = channel.id === "1"; // Default enabled for id "1"
            let price = this.productSKUdetail.SKUPrice ?? 0;
            let GP = channel.gpPercent
              ? util.convertCurrency(channel.gpPercent)
              : "NO GP";
            let advancedPricing = initializeAdvancedPricing();
            let purchasability = initializePurchasability();
            let sellingTimeId = null;

            // Find corresponding channel in producrChannelList
            const detailData = producrChannelList.find(
              (item) => item.ProductPRUType.id === channel.id
            );

            // If channel details exist, update values
            if (detailData) {
              enabled = detailData.ProductSKUChannel?.enabled ?? false;
              price = detailData.ProductPRU?.exceedUc ?? price;

                if (channel.id === "101") {
                  advancedPricing = {
                    ...advancedPricing,
                    deliveryOnDemand:
                      detailData.ProductPRU?.advancedPricing?.deliveryOnDemand ??
                      advancedPricing.deliveryOnDemand,
                    deliveryScheduled:
                      detailData.ProductPRU?.advancedPricing?.deliveryScheduled ??
                      advancedPricing.deliveryScheduled,
                    dineInOnDemand:
                      detailData.ProductPRU?.advancedPricing?.dineInOnDemand ??
                      advancedPricing.dineInOnDemand,
                };

                purchasability =detailData.ProductPRU?.purchasability ?? purchasability;
                sellingTimeId = detailData.ProductPRU?.sellingTimeId ?? null;

                    // Check if sellingTimeId exists and assign the sellingTime
                if (sellingTimeId) {

                  this.sellingTime = this.findSellingTimeById(sellingTimeId);
                }
              }
            }
            // Push to detail array
            detail.push({
              objectId: channel.objectId,
              gpPercent: GP,
              id: channel.id,
              name: channel.name,
              orderIndex: channel.orderIndex,
              typeGroup: channel.typeGroup,
              img: util.getDeliveryImg(channel.id),
              enabled,
              price: channel.id === "101" ? advancedPricing.deliveryOnDemand : price,
              isEdit: false,
              advancedPricing,
              purchasability,
              sellingTimeId,
            });
          });

          this.salesChannel = detail;
        })
        .catch((error) => {
          console.error("Error fetching sales channels:", error);
        });
    },
    getManageSaleHours() {
        this.loading = true;
        this.loadingButton = false;

        const shopObjectId = this.shopObjectId; // Retrieve shopObjectId
        const headers = { shopObjectId }; // Set headers

        pos.get('/api/v3.0/sellingchannel/sellingtime', { headers })
            .then((res) => {

                if (res.status === 200) {
                    let data = res.data.data
                    this.sellingTimelist = data

                } else {
                    console.error("Failed to fetch sales hours data. Status:", res.status);
                    this.data = [];
                }
            })
            .catch((error) => {
                console.error("Error fetching sales hours data:", error);
                this.data = [];
            })
            .finally(() => {
                // Update loading states
                this.loading = false;
                this.loadingButton = true;
            });
    },
    toggleDetails(item) {
      const position = this.details.indexOf(item);
      position !== -1
        ? this.details.splice(position, 1)
        : this.details.push(item);
    },
    findSellingTimeById(sellingTimeId) {
      // Check if sellingTimeId is provided
      if (!sellingTimeId) {
        return null;
      }

      // Search for the sellingTimeId in the sellingTimelist
      const result = this.sellingTimelist.find(item => item.id === sellingTimeId);

      if (result) {
        return result; // Return the matched object
      } else {
        return null; // Return null if not found
      }
    },
    updateProductPriceByChannel(item, index) {
      // Prepare data based on item.id
      const data = {
        enabled: item.enabled,
        productPRUType: {
          objectId: item.objectId,
          id: item.id,
          name: item.name,
        },
        productPLU: {
          objectId: this.productSKUdetail.ProductPLU.objectId,
          SKUName: this.productSKUdetail.ProductPLU.name,
          id: this.productSKUdetail.ProductPLU.id,
        },
      };

      // Additional properties for specific `item.id`
      if (item.id === '101') {
        Object.assign(data, {
          price: item.advancedPricing.deliveryOnDemand,
          advancedPricing: item.advancedPricing,
          purchasability: item.purchasability,
          sellingTimeId: this.sellingTime.id || null, // Default to null if undefined
        });
      } else {
        data.price = item.price; // For other cases, assign price directly
      }

      // Define headers
      const headers = {
        shopObjectId: this.shopObjectId,
      };

      // Make API request
      pos({
        method: 'POST',
        headers: headers,
        url: '/api/v3.0/sellingchannel/establish',
        data: data,
      })
        .then(() => {
          // Update UI state on success
          // this.salesChannel[index].isEdit = false;
        })
        .catch((error) => {
          // Log error for debugging
          console.error('Error updating product price by channel:', error);
        });
    },

    changeItemEdit(index) {
      this.salesChannel[index].isEdit = !this.salesChannel[index].isEdit;
    },
    updateChannelConfig(item, index) {
      let data = {
        enabled: item.enabled,
        productPRUType: {
          objectId: item.objectId,
          id: item.id,
          name: item.name,
        },
        productPLU: {
          objectId: this.productSKUdetail.ProductPLU.objectId,
          SKUName: this.productSKUdetail.ProductPLU.name,
          id: this.productSKUdetail.ProductPLU.id,
        },
      };
      // Handle specific cases for item.id == '101'
      if (item.id === '101') {
        data.price = item.advancedPricing.deliveryOnDemand;
        data.advancedPricing = item.advancedPricing;
        data.purchasability = item.purchasability;

        if (this.sellingTime) {
          data.sellingTimeId = this.sellingTime.id;
        }else{
          data.sellingTimeId = null
        }
      } else {
        // For all other cases
        data.price = item.price;
      }

      const shopObjectId = this.shopObjectId
      const headers = {
        shopObjectId: shopObjectId
      }
      pos({
        method: 'POST',
        headers: headers,
        url: '/api/v3.0/sellingchannel/establish',
        data: data,
      })
        .then(() => {
          this.salesChannel[index].isEdit = false
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
};
</script>
<style>
.hide-display {
  display: none !important;
}
.multiselect__tags {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}

.multiselect__single {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  font-size: 14px;
  cursor: pointer;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
